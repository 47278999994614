export const numberWithCommas = ( x ) => {
    if (x === 0) {
      return '';
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
 } 
 export const addCommas = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

export  const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";

  export const uknumberformat = (x) => {
    if (!x) return x; 
    const currentValue = x.toString().replace(/[^\d]/g, '');
    return `+${currentValue.slice(0, 2)} (${currentValue.slice(2, 3)})${currentValue.slice(3, 13)}`; 
}

export const uknumberformatFront = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `0${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(6, 13)}`; 
}
export const uknumberformatFront1 = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 4)} ${currentValue.slice(4, 7)} ${currentValue.slice(7, 13)}`; 
}

  export const uknumberformatwithoutbrace = (x) => {
    if (!x) return x; 
    const currentValue = x.toString().replace(/[^\d]/g, '');
    return `+${currentValue.slice(0, 2)} ${currentValue.slice(2, 6)} ${currentValue.slice(6, 13)}`; 
	}